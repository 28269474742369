import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      // component: lazy(() => import('../views/LandingPage'))
      component: () => <Redirect to={PATH_DASHBOARD.general.app} />
    },
    // {
    //   exact: true,
    //   path: '/components',
    //   component: lazy(() => import('../views/ComponentsOverview'))
    // },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
