/* eslint-disable import/no-duplicates */
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/app';
import { useEffect, ReactNode } from 'react';
import { createFirestoreInstance } from 'redux-firestore';
import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase';
// redux
import { store } from '../../redux/store';
// hooks
import useAuth from '../../hooks/useAuth';
//
import { firebaseConfig } from '../../config';

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // Uncomment below if you want to use emulator
  // firebase.firestore().settings({ host: 'localhost:9081', ssl:false });
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
};

// ----------------------------------------------------------------------

type FirebaseProviderProps = {
  children: ReactNode;
};

export default function FirebaseProvider({ children }: FirebaseProviderProps) {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const getInitialize = async () => {
      try {
        // Uncomment below if you want to use emulator
        // getFirebase().auth().useEmulator("http://localhost:9083");
        firebase.auth().onAuthStateChanged((user) => {
          if (user && isAuthenticated) {
            firebase
              .firestore()
              .collection('users')
              .doc(user.uid)
              .set(
                {},
                { merge: true }
              );
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    getInitialize();
  }, [isAuthenticated]);

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      {children}
    </ReactReduxFirebaseProvider>
  );
}
