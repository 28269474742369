const apiUrl = process.env.REACT_APP_API;

export async function createUser(values, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  return fetch(`${apiUrl}/api/user`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function updateUser(values, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/user`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function getCompany(code, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  return fetch(`${apiUrl}/api/company/code/${encodeURIComponent(code)}`, requestOptions)
    .then(res => res)
    .then(data => data)
    .catch((err) => {
      console.log(err);
    });
}

export async function getCompanyUsers(code, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  return fetch(`${apiUrl}/api/company/users/${encodeURIComponent(code)}`, requestOptions)
    .then(res => res)
    .then(data => data)
    .catch((err) => {
      console.log(err);
    });
}

export async function updateCompany(id, values, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/company/${encodeURIComponent(id)}`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function createContact(values, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/contact`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function updateContact(id, values, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/contact/${encodeURIComponent(id)}`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function getUsersPage(page, size, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/users?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function getContactsPage(page, size, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/contacts/page?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function queryCustomersData(page, size, query, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/contacts/search?query=${encodeURIComponent(query)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function getInboundHistoryPage(page, size, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/inboundsms?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function queryInboundHistoryData(page, size, query, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/sms/search?query=${encodeURIComponent(query)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function getHistoryPage(page, size, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/sms?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function queryHistoryData(page, size, query, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/sms/search?query=${encodeURIComponent(query)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function sendMessage(recipients, values, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      recipients,
      ...values // withName: true, message: "wiadomość"
    })
  };
  fetch(`${apiUrl}/api/sms/many`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function sendMessageToGroup(groupId, values, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      groupId,
      ...values // withName: true, message: "wiadomość"
    })
  };
  fetch(`${apiUrl}/api/sms/group`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function exportContacts(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  fetch(`${apiUrl}/api/contacts/export`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'kontakty.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function importContacts(selectedFile, token) {
  const formData = new FormData();
  formData.append('file', selectedFile);

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData
  };
  fetch(`${apiUrl}/api/contacts/import`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteContacts(contactIds, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(
      [...contactIds]
    )
  };
  fetch(`${apiUrl}/api/contacts/delete`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function createGroup(values, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/groups`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function updateGroup(id, values, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(values)
  };
  fetch(`${apiUrl}/api/group/${encodeURIComponent(id)}`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteGroups(groupsIds, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(
      [...groupsIds]
    )
  };
  fetch(`${apiUrl}/api/groups/delete`, requestOptions)
    .catch((err) => {
      console.log(err);
    });
}

export async function getGroupsPage(page, size, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/group/page?page=${encodeURIComponent(page)}&size=${encodeURIComponent(size)}`,
    requestOptions
  );
  return response.json();
}

export async function getGroupById(id, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/group/${encodeURIComponent(id)}`,
    requestOptions
  );
  return response.json();
}

export async function getCancellations(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/cancellations`,
    requestOptions
  );
  return response.json();
}

export async function updateCancellationToRead(id, token) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/cancellation/${id}`,
    requestOptions
  );
  return response.json();
}

export async function getStats(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const response = await fetch(
    `${apiUrl}/api/stats`,
    requestOptions
  );
  return response.json();
}

