import { motion } from 'framer-motion';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
//
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function NotActivatedScreen() {
  return (
    <RootStyle>
      <Box>
        <Logo sx={{ height: 64 }} />
      </Box>
      <Box mt={4}>
        <Typography variant="h5">
          Twoje konto nie zostało jeszcze aktywowane.
        </Typography>
      </Box>
    </RootStyle>
  );
}
