import React, { useEffect, useRef, useState } from 'react';
// material
import { Phone } from 'react-feather';
import { Connection, Device } from 'twilio-client';
import AccessToken from 'twilio/lib/jwt/AccessToken';
import axiosOriginal from 'axios';
// hooks
// components
import { MIconButton } from '../../components/@material-extend';
import PhoneWidget from './PhoneWidget';
import PhonePopover from './PhonePopover';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function TwilioPopover() {
  const { getToken } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('Not ready');
  const [calling, setCalling] = useState(false);
  const [connection, setConnection] = useState<Connection>();
  const device = new Device();

  async function getTokenT() {
    const tt = await getToken!();
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tt}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/voice/token`;
    const response = await axiosOriginal.get(reqString, requestOptions);
    const t = await response.data;
    return t;
  }

  useEffect(() => {
    getTokenT().then(t => {
      if (t === '')
        return;

      device.setup(t, {
        enableRingingState: true
      });
      device.on('ready', () => {
        setStatus("Ready");
      });
      device.on('error', () => setStatus("Error"));

      device.on('connect', (conn) => {
        console.log('Successfully established call!');
        setCalling(true);
        if ('phoneNumber' in conn.message) {
          console.log(`In call with ${conn.message.phoneNumber}`);
        } else {
          console.log('In call with support');
        }
      });

      device.on('disconnect', () => {
        setCalling(false);
        setStatus('Ready');
      });
    });

  }, [device]);

  async function handleCall(number: string) {
    const tt = await getToken!();
    const params = { 'phoneNumber': number, 'token': tt };
    const c = device.connect(params);
    console.log(tt, params, c);
    setConnection(c);
  }

  function handleHangup() {
    if (connection !== null) {
      connection!.disconnect();
    }
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: 'action.selected' })
        }}
      >
        <Phone />
      </MIconButton>

      <PhonePopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <PhoneWidget
          number=""
          handleCall={(number) => handleCall(number)}
          handleHangup={() => handleHangup()}
          calling={calling}
        />
      </PhonePopover>
    </>
  );
}
