import { Box, InputAdornment, SvgIcon, TextField } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/core/Autocomplete/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Search as SearchIcon } from 'react-feather';
import { getContactsPage, queryCustomersData } from '../../utils/apiCalls';
import useAuth from '../../hooks/useAuth';


export default function SearchContacts() {
  const { getToken } = useAuth();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const token = await getToken!();
      const jsun = await getContactsPage(0, 20, token);

      if (active) {
        setOptions(jsun.content);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  async function queryContacts(query: string) {
    const token = await getToken!();
    const jsun = await queryCustomersData(0, 20, query, token);

    setOptions(jsun.content);
  }

  return (
    <Box sx={{ width: { xs: 100, sm: 250, md: 300 }, my: 3 }}>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={
          (event, patient: any) => {
            if(patient !== null) {
              history.push(`/dashboard/patient/${patient.id}`);
            }
          }
        }
        getOptionSelected={(option: any, value: any) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={options}
        filterSelectedOptions
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Wyszukaj Pacjenta"
            variant="outlined"
            onKeyDown={(event: any) => queryContacts(event.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </Box>
  );
}
