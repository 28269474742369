import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo(props: BoxProps) {
  return (
    <Box
      px={2}
      component="img"
      alt="logo"
      src="/static/brand/logo_full.svg"
      height={40}
      {...props}
    />
  );
}
