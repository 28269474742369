import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dispatch } from '../store';
// utils


// ----------------------------------------------------------------------

type PatientState = {
  isLoading: boolean;
  error: boolean;
  profile: any;
  visits: any;
  stats: any;
};

const initialState: PatientState = {
  isLoading: false,
  error: false,
  profile: {},
  visits: [],
  stats: {}
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // UPDATE PROFILE
    updateProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    //
    getVisitsSuccess(state, action) {
      state.isLoading = false;
      state.visits = action.payload;
    },

    //
    getStatsSuccess(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getProfile(id: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const reqString = `${process.env.REACT_APP_API}/api/contact/${encodeURIComponent(id)}`;
      const response = await axios.get(reqString, { headers });
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePatient(
  profileId: string,
  profile: any,
  token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const reqString = `${process.env.REACT_APP_API}/api/contact/${encodeURIComponent(profileId)}`;
      const response = await axios.put(reqString, JSON.stringify(profile), { headers });
      dispatch(slice.actions.updateProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVisits(id: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const reqString = `${process.env.REACT_APP_API}/api/contact/visits/${encodeURIComponent(id)}`;
      const response = await axios.get(reqString, { headers });
      dispatch(slice.actions.getVisitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStats(id: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const reqString = `${process.env.REACT_APP_API}/api/contact/stats/${encodeURIComponent(id)}`;
      const response = await axios.get(reqString, { headers });
      dispatch(slice.actions.getStatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
