import faker from 'faker';
import { noCase } from 'change-case';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { set, sub, formatDistanceToNow, differenceInHours, addHours } from 'date-fns';
import { Bell } from 'react-feather';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar
} from '@material-ui/core';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { getCancellations, updateCancellationToRead } from '../../utils/apiCalls';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const NOTIFICATIONS: any[] = [
  {
    id: faker.datatype.uuid(),
    title: 'Powiadomienie',
    description: 'ładuję',
    avatar: null,
    type: 'chat_message',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  }
];

const NOTIFICATIONS_FULL = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: 'answered to your comment on the Minimal',
    avatar: mockImgAvatar(2),
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false
  }
];

type TNotificationPopover = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
};

function renderContent(notification: TNotificationPopover) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_package.svg"
        />
      ),
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_shipping.svg"
        />
      ),
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_mail.svg"
        />
      ),
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/static/icons/ic_notification_chat.svg"
        />
      ),
      title
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title
  };
}

function NotificationItem({
                            notification
                          }: {
  notification: TNotificationPopover;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItem
      button
      to="#"
      disableGutters
      key={notification.id}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
      <MIconButton color="primary" onClick={() => console.log('tet')}>
        <Icon icon={checkmarkFill} width={20} height={20} />
      </MIconButton>
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const { getToken } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true)
    .length;

  function formatDate(date: any) {
    date = date.toString(); // TODO: chceck if this works
    const d = date.split('T')[0];
    const t = date.split('T')[1].split('+')[0];
    return `${d} ${t}`;
  }

  useEffect(() => {
    getToken!().then(token => {
      getCancellations(token).then((ns: any[]) => {
        if(!Array.isArray(ns))
          return;
        {/* TODO: NIE MOŻE TAK ZOSTAĆ, ŻE NA FRONCIE DODAJESZ 2H */}
        const notifs = ns.filter(n => n.event != null).map(n => ({ id: n.id, title: `${n.contact.name} odwołał wizytę dnia ${formatDate(addHours(new Date(n.event.start), 0))}`, description: '',  avatar: null, type: 'chat_message', isUnRead: true, createdAt: sub(new Date(), { hours: differenceInHours(new Date(), new Date(n.updatedAt)) }) }));
        notifs.sort((a: any, b: any) => new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf() );
        setNotifications(notifs);
      });
    });

    setInterval(() => {
      getToken!()?.then(token => {
        getCancellations(token).then((ns: any[]) => {
          if(!Array.isArray(ns))
            return;
          const notifs = ns.filter(n => n.event != null).map(n => ({ id: n.id, title: `${n.contact.name} odwołał wizytę dnia ${formatDate(addHours(new Date(n.event.start), 0))}`, description: '',  avatar: null, type: 'chat_message', isUnRead: true, createdAt: sub(new Date(), { hours: differenceInHours(new Date(), new Date(n.updatedAt)) }) }));
          notifs.sort((a: any, b: any) => new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf() );
          setNotifications(notifs);
        });
      });
    }, 60000);

  }, []);


  const handleMarkAllAsRead = async () => {
    const token = await getToken!();
    notifications.map(n => {
      updateCancellationToRead(n.id, token);
    });
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={open ? 'primary' : 'default'}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Bell width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Powiadomienia</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Masz {totalUnRead} nieprzeczytanych wiadomości
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Oznacz wszystkie jako przeczytane">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                Nowe
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                Wcześniej
              </ListSubheader>
            }
          >
            {notifications.slice(2, 10).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            Zobacz wszystkie
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
