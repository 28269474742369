import React, { useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { PhoneCall, Delete, Phone } from 'react-feather';
// material
// hooks
// components

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  call: {
    backgroundColor: 'green'
  },
  hangup: {
    backgroundColor: 'red'
  }
}));

export default function PhoneWidget(props: {
  number: string,
  calling: boolean,
  handleCall: (number: string) => any,
  handleHangup: () => any
}) {
  const classes = useStyles();
  const { handleCall, handleHangup, calling } = props;
  const [number, setNumber] = useState<string>(props.number);

  function handleDigitClick(event: any) {
    const newNumber = number + event.target.innerText;
    setNumber(newNumber);
  }

  function handleBackspace() {
    const newNumber = number.substring(0, number.length - 1);
    setNumber(newNumber);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNumber(event.target.value);
  }

  return (
    <>
      <Grid
        container
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          <Box display="flex" justifyContent="center" px="auto" py={3}>
            <TextField value={number} onChange={handleChange} />
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>1</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>2</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>3</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>4</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>5</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>6</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>7</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>8</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>9</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>*</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>0</Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
        >
          <Box p={1}>
            <Button onClick={handleDigitClick}>#</Button>
          </Box>
        </Grid>
        {!calling &&
        <>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button className={classes.call} onClick={() => handleCall(number)} variant="contained">
                <PhoneCall />
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button onClick={handleDigitClick}>+</Button>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button onClick={handleBackspace}>
                <Delete />
              </Button>
            </Box>
          </Grid>
        </>
        }
        {calling &&
        <>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button className={classes.call} onClick={() => handleCall(number)} variant="contained">
                <PhoneCall />
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button onClick={handleDigitClick}>+</Button>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={4}
          >
            <Box p={1}>
              <Button className={classes.hangup} onClick={handleHangup} variant="contained">
                <Phone />
              </Button>
            </Box>
          </Grid>
        </>
        }
      </Grid>
    </>
  );
}
