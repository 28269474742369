import { makeStyles, useTheme } from '@material-ui/core';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import getInitials from '../utils/getInitials';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  avatar: {
    paddingBottom: theme.spacing(0.5)
  }
}));


export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const classes = useStyles();
  const theme = useTheme();

  // if (!user.photoURL) return null;

  return (
    <MAvatar
      className={classes.avatar}
      // src={user.photoURL}
      alt={user.displayName}
      color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      {...other}
    >
      {getInitials(user.displayName)}
    </MAvatar>
  );
}
